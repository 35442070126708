import React, { useState } from 'react'
import axios from 'axios'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

function Movie(props){
  const [requested, setRequested] = useState(false)
  const [info, setInfo] = useState(false)

  function Request(body){
    console.log(body)
    let endpoint = '/add'
    if(window.location.href.includes('localhost')){
      endpoint = 'http://localhost:4202/add'
    }

    axios({
      method: 'post',
      url: endpoint,
      data: {
        title: body.title,
        url: body.url
      }
    }).then(r=>{
      console.log(r)
      alert(r.data)
    })
  }

  function onClick(e){
    let chosen
    const highQ = props.m.torrents.filter(torr => torr.quality=='1080p')
    const medQ = props.m.torrents.filter(torr => torr.quality=='720p')

    if(highQ.length > 0) chosen=highQ[0]
    else chosen=medQ[0]
    
    setRequested(true)
    Request({title: props.m.title_long, url: chosen.url})
  }

  function handleInfo(){
    if (info) setInfo(false)
    else setInfo(true)
  }

  function Info(){
    if(info){
      return (
        <Card.ImgOverlay>
        <Card.Title>{props.m.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{props.m.year}</Card.Subtitle>
        <Card.Text>Genres: {props.m.genres.map(g=>`${g} `)}</Card.Text>
        <Card.Text>
          Rating: {props.m.rating}/10
        </Card.Text>
        </Card.ImgOverlay>
      )
    }else return <></>
  }

  return(
  <Card className="bg-dark text-white" style={{ width: '18rem' }} onClick={handleInfo}>
    <Card.Img variant="top" src={props.m.medium_cover_image}/>
    <Info/>
    <Card.Footer>
      <Button variant="primary" disabled={requested} onClick={onClick}>{!requested ? 'Request' : 'Requested'}</Button>
      {/* <Button onClick={()=>props.Similar(props.m.id)}>Find Similar</Button> */}
    </Card.Footer>
  </Card>
  )
}

export default Movie