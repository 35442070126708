import React, { useState } from 'react'
import CardColumns from 'react-bootstrap/CardColumns'
import Container from 'react-bootstrap/Container'
import Movie from './Movie'

import './App.css';

function Search() {
  const [results, setResults] = useState([])
  const [input,setInput] = useState('')
  const [isLoading,setIsLoading] = useState(false)
  
  function onClick(){
    setIsLoading(true)
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      const json = JSON.parse(xhr.responseText)
      console.log(json)
      setResults(json.data.movies)
      setIsLoading(false)
    })
    xhr.open('GET', `https://yts.mx/api/v2/list_movies.json?query_term=${input}`)
    xhr.send()
  }
  
  function ListMovies(){
    if(results){
      return <CardColumns>
        {results.map((m, index) => {
          return <Movie key={m.title} m={m} Request={Request} Similar={Similar}/>
        })}
      </CardColumns>
    }else{
      return <div>No Results</div>
    }
  }

  function Similar(id){
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      const json = JSON.parse(xhr.responseText)
      console.log(json)
      setResults(json.data.movies)
    })
    xhr.open('GET', `https://yts.mx/api/v2/movie_suggestions.json?movie_id=${id}`)
    xhr.send()
  }

  function Request(movie){
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `/add/${movie}`)
    xhr.send()
  }
  
  function onKeyPress(e){
    if(e.key==='Enter') onClick()
  }

  return (
    <Container>
      <h3>Plex Request</h3>
        <label>
          Name:
          <input type="text" name="name" value={input} onChange={(e)=>setInput(e.target.value)} onKeyPress={(e)=>onKeyPress(e)}/>
        </label>
        <button onClick={onClick}>{isLoading ? 'Loading...' : 'Search' }</button>
        <ListMovies />
    </Container>
  )
}

export default Search
